import axios from "axios";
export const header = {};

export const apiGetMethod = (url, params = "") => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}${url}${params}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (e) {
    }
  });
};
export const apiPostMethod = (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}${url}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const apiDeleteMethod = (url, param = "") => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}${url}/${param}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiPutMethod = (url, data, param = "") => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}${url}/${param}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
