import { ImageAvatar } from "../../shared/constants/widgetsHtml";
import { isEmpty } from "../../shared/functions/isEmpty";

function Header({ formSettings, activePage = 0 }) {
  if (formSettings.title == undefined) {
    return;
  }
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="form-group cardOuter">
          {!isEmpty(formSettings.logo) && (
            <div className="abc">
              <ImageAvatar data={{ src: formSettings.logo }} />
            </div>
          )}

          <div key={formSettings.title.id} id={formSettings.title.id}>
            <h2
              style={{
                fontWeight: formSettings.title.bold ? "bold" : "",
                fontStyle: formSettings.title.italic ? "italic" : "",
              }}
            >
              {formSettings.title.label ? formSettings.title.label : "Title"}
            </h2>
            {/* <button
              style={{ marginLeft: "80%" }}
              className="btn btn-secondary me-2"
              onClick={() => {downloadButton(true)}}
            >
              Download PDF
            </button> */}
          </div>

          {formSettings.title && formSettings.title.pages.length > 1 && (
            <div className="pagesSection">
              {formSettings.title.pages &&
                formSettings.title.pages.map((i, index) => {
                  return (
                    <a
                      key={`choice__${index}`}
                      className={activePage == index ? "active" : ""}
                    >
                      <span>{index + 1}</span>
                      {i.label}
                    </a>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Header;
