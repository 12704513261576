import { uniqueID } from "../functions";
const Widgets = {
  Paragraph: {
    width: 6,
    minRequiredWidth: 2,
    name: "Paragraph",
    label: "",
    labelActualValue: "",
    element: "Paragraph",
    userContentEditable: false,
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
  },
  TextInput: {
    width: 4,
    minRequiredWidth: 2,
    name: "Text Box",
    value: "",
    label: "",
    labelActualValue: "",
    setInputValueRange: false,
    element: "TextInput",
    showDescription: false,
    required: true,
    placeholder: "",
    type: "text",
    input_types: [
      {
        id: "Radio_Option_" + uniqueID(6),
        text: "Text Single-Line",
        value: "text",
      },
      {
        id: "Radio_Option_" + uniqueID(6),
        text: "Text Multi-Line",
        value: "textarea",
      },
      {
        id: "Radio_Option_" + uniqueID(6),
        text: "Password",
        value: "password",
      },
      { id: "Radio_Option_" + uniqueID(6), text: "Number", value: "number" },
      { id: "Radio_Option_" + uniqueID(6), text: "Email", value: "email" },
      { id: "Radio_Option_" + uniqueID(6), text: "Phone", value: "tel" },
    ],
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    valueEditorType: "text",
    inputType: "text",
  },
  CheckBoxes: {
    width: 4,
    minRequiredWidth: 2,
    name: "Choice",
    value: "",
    label: "",
    labelActualValue: "",
    element: "CheckBoxes",
    showDescription: false,
    required: true,
    hasValues: false,
    options: [
      {
        id: "CheckBoxes_Option_" + uniqueID(4),
        key: "first",
        value: "",
        checked: false,
      },
      {
        id: "CheckBoxes_Option_" + uniqueID(4),
        key: "Second",
        value: "",
        checked: false,
      },
      {
        id: "CheckBoxes_Option_" + uniqueID(4),
        key: "Third",
        value: "",
        checked: false,
      },
    ],
    typeRadio: false,
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    valueEditorType: "radio",
    inputType: "radio",
  },
  DropDown: {
    width: 4,
    minRequiredWidth: 2,
    name: "Select Drop Down",
    value: "",
    label: "",
    labelActualValue: "",
    element: "DropDown",
    showDescription: false,
    required: true,
    optionType: "static",
    options: [
      { id: "DropDown_Option_" + uniqueID(4), value: "first" },
      { id: "DropDown_Option_" + uniqueID(4), value: "Second" },
      { id: "DropDown_Option_" + uniqueID(4), value: "Third" },
    ],
    defaultValue: 0,
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    valueEditorType: "select",
    inputType: "select",
  },
  SelectDatePicker: {
    width: 2,
    minRequiredWidth: 2,
    name: "Date Picker",
    value: "",
    label: "",
    labelActualValue: "",
    element: "SelectDatePicker",
    showDescription: false,
    required: true,
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    inputType: "date",
  },
  FileUpload: {
    width: 2,
    minRequiredWidth: 2,
    name: "Upload File",
    value: "",
    label: "",
    labelActualValue: "",
    min_value: undefined,
    max_value: 100,
    element: "FileUpload",
    showDescription: false,
    required: true,
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    valueEditorType: "text",
    inputType: "text",
  },
  SelectTimePicker: {
    width: 4,
    minRequiredWidth: 2,
    name: "Time Picker",
    value: null,
    label: "",
    labelActualValue: "",
    element: "SelectTimePicker",
    showDescription: false,
    required: true,
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    inputType: "time",
  },
  RatingScale: {
    width: 6,
    minRequiredWidth: 6,
    name: "Rating Scale",
    value: null,
    label: "",
    element: "RatingScale",
    labelActualValue: "",
    showDescription: false,
    fields: [
      { id: "RatingScale_" + uniqueID(4), label: "Question 1", value: "" },
      { id: "RatingScale_" + uniqueID(4), label: "Question 2", value: "" },
      { id: "RatingScale_" + uniqueID(4), label: "Question 3", value: "" },
    ],
    typesOfRating: [
      {
        type: "Satisfied/Unsatisfied",
        structure: [
          "Very Unsatisfied",
          "Unsatisfied",
          "Neutral",
          "Satisfied",
          "Very Satisfied",
        ],
      },
      {
        type: "Agree/Disagree",
        structure: [
          "Strongly Disagree",
          "Disagree",
          "Neutral",
          "Agree",
          "Strongly Agree",
        ],
      },
      {
        type: "Will/Won't",
        structure: [
          "Definitely Won`t",
          "Probably Won`t",
          "Not Sure",
          "Probably Will",
          "Definitely Will",
        ],
      },
      {
        type: "Good/Poor",
        structure: ["Very Poor", "Poor", "Average", "Good", "Very Good"],
      },
      { type: "Scale of 1 to 5", structure: ["1", "2", "3", "4", "5"] },
      {
        type: "Custom",
        structure: [
          "Very Unsatisfied",
          "Unsatisfied",
          "Neutral",
          "Satisfied",
          "Very Satisfied",
        ],
      },
    ],
    selectedRatingType: "Satisfied/Unsatisfied",
    required: true,
    defaultRadioValue: 0,
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    valueEditorType: "multiselect",
    inputType: "multiselect",
  },
  Signature: {
    width: 4,
    minRequiredWidth: 4,
    name: "Signature",
    value: null,
    label: "",
    labelActualValue: "",
    element: "Signature",
    showDescription: false,
    required: true,
    fields: {
      Name: { id: "Signature_Name_" + uniqueID(4), name: "Name", value: "" },
    },
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    valueEditorType: "text",
    inputType: "text",
  },
  ContactInformation: {
    width: 6,
    minRequiredWidth: 4,
    name: "Contact Information",
    value: null,
    label: "",
    labelActualValue: "",
    element: "ContactInformation",
    showDescription: false,
    required: true,
    fields: {
      Name: { id: "ContactInfo_Name_" + uniqueID(4), name: "Name", value: "" },
      ContactPh: {
        id: "ContactInfo_ContactPh_" + uniqueID(4),
        name: "Contact Ph",
        value: "",
      },
      Address: {
        id: "ContactInfo_Address_" + uniqueID(4),
        name: "Address",
        value: "",
      },
      City: { id: "ContactInfo_City_" + uniqueID(4), name: "City", value: "" },
      State: {
        id: "ContactInfo_State_" + uniqueID(4),
        name: "State",
        value: "",
      },
      Zip: { id: "ContactInfo_Zip_" + uniqueID(4), name: "Zip", value: "" },
      ContactEmail: {
        id: "ContactInfo_Email_" + uniqueID(4),
        name: "Contact Email",
        value: "",
      },
    },
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    valueEditorType: "text",
    inputType: "text",
  },
  Content: {
    width: 6,
    minRequiredWidth: 2,
    name: "Content",
    value: null,
    DynamicContent: "",
    element: "Content",
    showDescription: false,
    required: true,
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
  },

  Calculation: {
    width: 4,
    minRequiredWidth: 2,
    name: "Calculation",
    value: "",
    label: "",
    labelActualValue: "",
    element: "Calculation",
    showDescription: false,
    required: true,
    type: "number",
    input_types: [
      {
        id: "Radio_Option_" + uniqueID(6),
        text: "Number",
        value: "number",
      },
      { id: "Radio_Option_" + uniqueID(6), text: "Percent", value: "percent" },
      {
        id: "Radio_Option_" + uniqueID(6),
        text: "Currency",
        value: "currency",
      },
    ],
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    limitQuantity: {
      type: "never",
      rules: { combinator: "and", rules: [] },
    },
    customError: {
      type: "never",
      rules: { combinator: "and", rules: [] },
    },
    calculation: "",
    helptext: "",
  },
};
const Layout = {
  PageBreak: {
    name: "Page Break",
    pageNumber: 0,
    buttons: [
      {
        label: "Next",
        hidden: false,
        type: "Next",
        conditionalOperation: {
          type: "always",
          rules: { combinator: "and", rules: [] },
        },
      },
      { label: "Back", hidden: true, type: "Previous" },
    ],
    element: "PageBreak",
    width: 12,
    minRequiredWidth: 12,
    widthEditable: false,
  },
  Section: {
    name: "Section",
    element: "Section",
    label: "",
    width: 12,
    minRequiredWidth: 6,
    childComponent: [],
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
  },
  RepeatingSection: {
    name: "Repeating",
    element: "RepeatingSection",
    label: "",
    width: 12,
    minRequiredWidth: 6,
    childComponent: [],
    itemLabel: "",
    addButtonText: "Add Item",
    numberofItems: { min: 5, max: 10 },
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
  },
};
const Media = {
  Image: {
    name: "Image",
    element: "Image",
    label: "",
    width: 6,
    minRequiredWidth: 2,
    src: "",
    accept: "image/png, image/gif, image/jpeg",
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
  },
  Video: {
    name: "Video",
    element: "Video",
    width: 6,
    minRequiredWidth: 4,
    label: "",
    src: "",
    image_width: "560",
    image_height: "315",
    accept: "video/mp4, video/x-m4v, video/*",
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
  },
  ImageSlider: {
    name: "Image Slider",
    element: "ImageSlider",
    label: "",
    width: 6,
    minRequiredWidth: 6,
    images: [
      {
        id: "RatingScale_" + uniqueID(4),
        src: "",
        value: "",
        altName: "Legend",
      },
    ],
    accept: "image/png, image/gif, image/jpeg",
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
  },
};
const FormSettings = {
  title: {
    id: `Title_${uniqueID(4)}`,
    label: "",
    pages: [],
  },
  description: "",
  formActionSettings: [
    {
      type: "submit",
      entry_endpoint: "",
    },
    {
      type: "update",
      entry_endpoint: "",
    },
    {
      type: "delete",
      entry_endpoint: "",
    },
  ],
  extra_setting: {},
};

const Submit = {
  name: "Submit",
  element: "Submit",
  showDescription: false,
  buttons: [
    {
      buttonText: "Submit",
      label: "submit",
      eventType: "Save",
      actionName: "submit",
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      confirmationType: "message",
      confirmationRedirection: "",
      confirmationMessage:
        "Thank you for filling out the form. Your response has been recorded.",
      changeStatusTo: null,
    },
    {
      buttonText: "Update",
      label: "Update",
      eventType: "update",
      actionName: "Update",
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      confirmationType: "message",
      confirmationRedirection: "",
      confirmationMessage:
        "Thank you for filling out the form. Your response has been recorded.",
      changeStatusTo: null,
    },
  ],
  statusTypes: [
    {
      label: "Incompleted",
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      color: "#000",
    },
    {
      label: "Submitted",
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      color: "#FFFFFF",
    },
  ],
  width: 12,
  fixed: true,
  listingHidden: true,
  conditionalOperation: {
    type: "always",
    rules: { combinator: "and", rules: [] },
  },
  extra_setting: {},
};

const AddButtonJson = {
  buttonText: "Action",
  label: "Action",
  eventType: "action",
  actionName: "Action",
  conditionalOperation: {
    type: "always",
    rules: { combinator: "and", rules: [] },
  },
  confirmationType: "message",
  confirmationRedirection: "",
  confirmationMessage:
    "Thank you for filling out the form. Your response has been recorded.",
  changeStatusTo: null,
};
const addStatusJson = {
  label: "Status",
  conditionalOperation: {
    type: "always",
    rules: { combinator: "and", rules: [] },
  },
  color: "#FFFFFF",
};

export {
  Widgets,
  Layout,
  Media,
  Submit,
  AddButtonJson,
  addStatusJson,
  FormSettings,
};
