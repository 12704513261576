import { useEffect, useState } from "react";
import {
  deepClone,
  getConditionalStringResponse,
} from "../../shared/functions";
import * as formHtml from "../../shared/constants/formHtml";
import Header from "./header";
import Footer from "./footer";
import { isEmpty } from "../../shared/functions/isEmpty";

function PreviewForm({ type, jsonData }) {
  const [form, setForm] = useState({});
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [splitForm, setSplitForm] = useState([]);
  const [previewWidgetsData, setPreviewWidgetsData] = useState([]);
  const [formSettings, setFormSettings] = useState({});
  const [downloadButton, setdownloadButton] = useState(false);
  const [formSubmitSettings, setformSubmitSettings] = useState({});

  useEffect(() => {
    let WidgetsData = {};
    let settings = {};
    let submitSettings = {};
    if (type === "id") {
      WidgetsData = jsonData.structure;
      settings = jsonData.formSettings;
      submitSettings = jsonData.submitSettings;
    } else {
      WidgetsData = JSON.parse(localStorage.getItem("previewWidgetsData"));
      settings = JSON.parse(localStorage.getItem("formSettings"));
      submitSettings = JSON.parse(localStorage.getItem("formSubmitSettings"));
    }
    if (WidgetsData && WidgetsData.length > 0) {
      setFormSettings(settings);
      setformSubmitSettings(submitSettings);
      if (settings.title.pages.length > 0) {
        let Obj = [];
        let paginateForm = [];
        WidgetsData.map((json, index) => {
          json.childComponent.map((data, refVal) => {
            if (data.element == "PageBreak") {
              data.structure = deepClone(Obj);
              Obj = [];
              paginateForm.push(data);
            } else {
              if (refVal == 0) {
                Obj.push(json);
              }
              if (index == WidgetsData.length - 1) {
                let submitData = { structure: deepClone(Obj) };
                paginateForm.push(submitData);
              }
            }
          });
        });
        setSplitForm(paginateForm);
      } else {
        setPreviewWidgetsData(WidgetsData);
      }
    }
  }, []);

  useEffect(() => {
    if (previewWidgetsData.length > 0) {
      let FormData = {};
      previewWidgetsData.map((json) => {
        json.childComponent.map((data) => {
          if (data.hasOwnProperty("fields")) {
            let structure = {};
            Object.keys(data.fields).map((field) => {
              structure[field] = data.fields[field].value;
            });
            FormData[data.id] = { ...structure };
          } else {
            FormData[data.id] = "";
          }
        });
      });
      setForm({ ...FormData });
    }
  }, [previewWidgetsData]);

  return (
    <div id="previewModal">
      <Header
        formSettings={formSettings}
        activePage={activePageIndex}
        // downloadButton={downloadButton}
      />
      {!isEmpty(previewWidgetsData) && (
        <>
          {previewWidgetsData.map((data, index) => {
            return (
              <div className="row" key={`preview_widgets_${index}`}>
                {data?.childComponent?.map((row, refVal) => {
                  let Tag = formHtml[row.element];
                  let props = {};
                  if (row.conditionalOperation?.rules.rules.length > 0) {
                    if (
                      !getConditionalStringResponse(
                        row.conditionalOperation.rules
                      )
                    ) {
                      return;
                    }
                  }

                  return (
                    <div
                      className={`col-lg-${row.width ? row.width : 6}`}
                      key={`childComponent_${row.id}`}
                    >
                      <div className="form-group">
                        {form[row.id] !== undefined && (
                          <Tag
                            {...props}
                            data={row}
                            onChangeFields={(value) =>
                              setForm((prevData) => {
                                prevData[row.id] = value;
                                return { ...prevData };
                              })
                            }
                            formData={form[row.id]}
                            form={form}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
          <Footer formSubmitSettings={formSubmitSettings} />
        </>
      )}
      {splitForm.length > 0 && (
        <>
          {splitForm[activePageIndex].structure.map((data) => {
            return (
              <div className="row">
                {data.childComponent.map((row, refVal) => {
                  let Tag = formHtml[row.element];
                  if (row.conditionalOperation?.rules.rules.length > 0) {
                    if (
                      !getConditionalStringResponse(
                        row.conditionalOperation.rules
                      )
                    ) {
                      return;
                    }
                  }
                  return (
                    <div
                      className={`col-lg-${row.width ? row.width : 6}`}
                      key={row.id}
                    >
                      <div className="form-group">
                        <Tag
                          data={row}
                          onChangeFields={(value) =>
                            setForm((prevData) => {
                              prevData[row.id] = value;
                              return { ...prevData };
                            })
                          }
                          formData={form[row.id]}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
          {activePageIndex === splitForm.length - 1 ? (
            <Footer formSubmitSettings={formSubmitSettings} />
          ) : (
            <div>
              <div className="pagebreak text-start">
                {splitForm[activePageIndex].buttons.map((button, index) => {
                  if (button.hidden) {
                    return null;
                  }
                  return (
                    <button
                      key={`choice__${index}`}
                      type="button"
                      className=""
                      disabled={
                        button.conditionalOperation.rules.rules.length > 0
                          ? !getConditionalStringResponse(
                              button.conditionalOperation.rules
                            )
                          : false
                      }
                      onClick={() => {
                        setActivePageIndex(activePageIndex + 1);
                      }}
                    >
                      {" "}
                      {button.label}
                    </button>
                  );
                })}
                <div className="pagesplit-top"></div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default PreviewForm;
