import FormListing from "../../components/FormListing"
import SubFormListing from "../../components/SubFormListing"
// import Logo from '../../assets/img/logo.svg';
import Logo from '../../assets/img/logo.svg';
import './style.css'

function FormsList() {
    return (
        <>
            <div className="header fixed-container">
                <a className="brandName" href="/"><img src={Logo}></img></a>

                <div className="d-flex">
                    <a href="/form-builder" className="btn btn-save" >Create New Form</a>
                </div>
            </div>
            <div className="FormsList header-margin">
                <div className="tableListing">
                    <table className="table">
                        <tbody>
                            <FormListing />

                        </tbody>
                        <tbody>
                            <tr>
                                <td colSpan="3" className="form-heading">JSA Codes</td>
                            </tr>
                            <SubFormListing />
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default FormsList;
