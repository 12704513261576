/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import DatePicker from "react-date-picker";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import * as widgetsHtml from "./widgetsHtml";
import videoIcon from "../../assets/img/videoIcon.svg";
import imageIcon from "../../assets/img/imageIcon.svg";
import carouselIcon from "../../assets/img/carouselIcon.svg";
import crossIcon from "../../assets/img/cancelImg.svg";
import plusIcon from "../../assets/img/plusIcon.svg";
import SignatureCanvas from "react-signature-canvas";
import { useEffect, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Widgets, Layout, Media } from "../../shared/constants/widgetJson";
import { uniqueID, fetchImage, deepClone } from "../functions";
import { Avatar } from "@mui/material";
import { isEmpty } from "../functions/isEmpty";
import { highlightTextWithBackground } from "../functions/string-conversion";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function fetchS3Link(key) {
  let NewSrc = fetchImage(key);
  return NewSrc.then((res) => {
    return res;
  });
}
export const TextInput = ({ data, onChangeFields }) => {
  let Tag = data.type !== "textarea" ? "input" : "textarea";
  return (
    <form key={data.id} id={data.id}>
      <label>
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmpty(data.label)
              ? highlightTextWithBackground(data.label)
              : "Untitled",
          }}
        />
        <small>{data.required ? "" : " Optional"}</small>
      </label>
      <Tag
        type={data.type}
        className="form-control"
        minLength={
          data.setInputValueRange && data.min_value ? data.min_value : null
        }
        maxLength={
          data.setInputValueRange && data.max_value ? data.max_value : null
        }
        placeholder={data.placeholder ? data.placeholder : ` ${data.type}`}
        onChange={(e) => onChangeFields(data, e)}
        name={data.label ? data.label : "Untitled"}
        value={data.value}
      />
    </form>
  );
};

export const CheckBoxes = ({ data, onChangeFields }) => {
  return (
    <form key={data.id} id={data.id}>
      <label>
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmpty(data.label)
              ? highlightTextWithBackground(data.label)
              : "Untitled",
          }}
        />
        <small>{data.required ? "" : " Optional"}</small>
      </label>
      {data.options.map((opt) => {
        return (
          <div key={`options_form_${opt.key}`}>
            <input
              className="form-check-input"
              data={data.typeRadio}
              type={data.typeRadio ? "radio" : "checkbox"}
              name={data.label ? data.label : "Untitled"}
              // checked={opt.checked}
              onChange={(e) => onChangeFields(data, e, opt)}
            />
            &nbsp;
            <label value={data.hasValues ? opt.value : opt.key}>
              {opt.key}
            </label>
          </div>
        );
      })}
    </form>
  );
};
export const DropDown = ({ data, onChangeFields }) => {
  return (
    <form key={data.id} id={data.id}>
      <label>
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmpty(data.label)
              ? highlightTextWithBackground(data.label)
              : "Untitled",
          }}
        />
        <small>{data.required ? "" : " Optional"}</small>
      </label>
      <select
        className="form-control"
        value={data.defaultValue}
        onChange={(e) => onChangeFields(data, e)}
        name={data.label ? data.label : "Untitled"}
      >
        {data.options.map((opt, index) => {
          return (
            <option key={`options_form_2${opt.value}`} value={index}>
              {opt.value}
            </option>
          );
        })}
      </select>
    </form>
  );
};
export const SelectDatePicker = ({ data, onChangeFields }) => {
  return (
    <form key={data.id} id={data.id}>
      <label>
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmpty(data.label)
              ? highlightTextWithBackground(data.label)
              : "Untitled",
          }}
        />
        <small>{data.required ? "" : " Optional"}</small>
      </label>
      <br />
      <DatePicker
        onChange={(e) => onChangeFields(data, e)}
        name={data.label ? data.label : "Untitled"}
        value={data.value ? data.value : data.defaultValue}
        clearIcon={null}
      />
    </form>
  );
};
export const FileUpload = ({ data, onChangeFields }) => {
  return (
    <form key={data.id} id={data.id}>
      <label>
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmpty(data.label)
              ? highlightTextWithBackground(data.label)
              : "Untitled",
          }}
        />
        <small>{data.required ? "" : " Optional"}</small>
      </label>
      <br />
      <input
        type="file"
        accept={data.fileType}
        name={data.label ? data.label : "Untitled"}
        onChange={(e) => onChangeFields(data, e)}
      />
    </form>
  );
};
export const Paragraph = ({ data }) => {
  const editorRef = useRef(null);
  const [showEditor, setShowEditor] = useState(false);

  return (
    <div key={data.id} id={data.id}>
      {data.userContentEditable && (
        <button
          className="editBtn-para"
          onClick={(e) => {
            data.userContentEditable && setShowEditor(!showEditor);
          }}
        >
          <a role="button" type="button">
            <i className="fa-regular fa-pen-to-square"></i>{" "}
          </a>
        </button>
      )}
      {!showEditor ? (
        <p
          style={{
            fontWeight: data.bold ? "bold" : "",
            fontStyle: data.italic ? "italic" : "",
          }}
          dangerouslySetInnerHTML={{
            __html: data.label
              ? JSON.parse(highlightTextWithBackground(data.label))
              : "Content Here",
          }}
        ></p>
      ) : (
        <>
          <ReactQuill
            value={data.label ? JSON.parse(data.label) : ""}
            onChange={(e) => {
              // editElementProp(JSON.stringify(e), 'label')
            }}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline"],
              ],
            }}
            theme="snow"
          />
        </>
      )}
    </div>
  );
};
export const Calculation = ({ data }) => {
  return (
    <div className="grid" style={{ wordWrap: "break-word" }}>
      <form key={data.id} id={data.id}>
        <label>
          <span
            dangerouslySetInnerHTML={{
              __html: !isEmpty(data.label)
                ? highlightTextWithBackground(data.label)
                : "Untitled",
            }}
          />
          <small>{data.required ? "" : " Optional"}</small>
        </label>
        <div>{data.calculation ? data.calculation : "Calculation(Text)"}</div>
        <div>{data.helptext ? data.helptext : ""}</div>
      </form>
    </div>
  );
};
export const PageBreak = ({ data }) => {
  return (
    <div key={data.id} id={data.id}>
      <div className="pagebreak">
        {data.buttons.map((button, index) => {
          if (button.hidden) {
            return null;
          }
          return (
            <button key={`choice00__${index}`} type="button" className="">
              {button.label}
            </button>
          );
        })}
        <div className="pagesplit-top"></div>
        <div className="pagesplit-bottom"></div>
      </div>
    </div>
  );
};
export const Section = (props) => {
  // selectedSchema index data onChangeFields handleOnDrop UpdateSectionUpdate sectionIndex setSelectedWidget openModal setSelectedIndex
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [activeDropBox, setActiveDropBox] = useState([]);
  let dragErrorStatus = false;

  useEffect(() => {
    setSelectedWidgets([...props.data.childComponent]);
  }, []);
  const [selectedWidget, setSelectedWidget] = useState({});
  useEffect(() => {
    if (selectedWidget.hasOwnProperty("element"))
      props.setSelectedWidget(selectedWidget);
  }, [selectedWidget]);
  useEffect(() => {
    if (props.data && props.data.childComponent && selectedWidgets.length > 0) {
      props.data.childComponent = [...selectedWidgets];
      props.UpdateSectionUpdate(props.data, props.index, props.sectionIndex);
    }
  }, [selectedWidgets]);

  const [selectedIndex, setSelectedIndex] = useState([0, 0]);
  useEffect(() => {
    props.setSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  const onChangeFields = (item, e, opt = null) => {
    console.log("onChangeFields ===>", e);
  };

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleOnDrop(e, index, subIndex) {
    let widgetType = JSON.parse(
      e.dataTransfer.getData("moveWidget") ||
        e.dataTransfer.getData("widgetType") ||
        null
    );
    if (widgetType !== undefined || widgetType !== null) {
      if (widgetType) {
        let jsonData = {};
        if (typeof widgetType === "string") {
          if (Widgets[widgetType] !== undefined) {
            jsonData.childComponent = [deepClone(Widgets[widgetType])];
          } else if (Layout[widgetType] !== undefined) {
            jsonData.childComponent = [deepClone(Layout[widgetType])];
          } else {
            jsonData.childComponent = [deepClone(Media[widgetType])];
          }
          jsonData.childComponent[0]["id"] = `${
            jsonData.childComponent[0].element
          }_${uniqueID(4)}`;
          jsonData.childComponent[0].width = jsonData.childComponent[0].width
            ? jsonData.childComponent[0].width
            : 6;
          if (
            index !== undefined &&
            subIndex !== undefined &&
            selectedWidgets[index]
          ) {
            selectedWidgets[index].childComponent[subIndex].childComponent.push(
              jsonData
            );
          } else {
            selectedWidgets.push(jsonData);
          }
        } else {
          if (widgetType.hasOwnProperty("json")) {
            selectedWidgets.push({ childComponent: [widgetType.json] });
          } else {
            selectedWidgets.push({ childComponent: [widgetType] });
          }
        }
        setSelectedWidgets([...selectedWidgets]);
      }
    }
    e.dataTransfer.setData("moveWidget", null);
    e.dataTransfer.setData("widgetType", null);
  }

  function sectionHandleOnDrop(e, index) {
    let widgetType = JSON.parse(
      e.dataTransfer.getData("moveWidget") ||
        e.dataTransfer.getData("widgetType") ||
        null
    );
    let width = 0;
    let usedWidth = 0;
    let totalWidth = 12;
    if (selectedWidgets[index] == undefined) {
      if (widgetType.path[0] < index) {
        index -= 1;
      } else {
        index += 1;
      }
    }
    selectedWidgets[index].childComponent.map((row, refVal) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    if (widthRemaining > 6) {
      width = 6;
    } else {
      width = widthRemaining;
    }
    let widgetJson = {};
    if (typeof widgetType == "string") {
      if (Widgets[widgetType] !== undefined) {
        widgetJson = deepClone(Widgets[widgetType]);
      } else if (Layout[widgetType] !== undefined) {
        widgetJson = deepClone(Layout[widgetType]);
      } else {
        widgetJson = deepClone(Media[widgetType]);
      }

      widgetJson["id"] = `${widgetJson.element}_${uniqueID(4)}`;
    } else {
      widgetJson = widgetType.json;
    }

    if (
      widgetJson.hasOwnProperty("minRequiredWidth") &&
      widgetJson.minRequiredWidth > width
    ) {
      dragErrorStatus = true;
      alert(
        `Not allowed, minimum width reduired is ${widgetJson.minRequiredWidth}`
      );
    } else {
      selectedWidgets[index].childComponent.push(
        deepClone({ ...widgetJson, ...{ width: width } })
      );
      setSelectedWidgets([...selectedWidgets]);
    }
    e.dataTransfer.setData("moveWidget", null);
    e.dataTransfer.setData("widgetType", null);
  }

  function removeWidget(index, refVal) {
    if (selectedWidgets[index].childComponent.length > 1) {
      selectedWidgets[index].childComponent.splice(refVal, 1);
    } else {
      selectedWidgets.splice(index, 1);
    }
    setSelectedWidgets([...selectedWidgets]);
  }

  function UpdateSectionUpdate(data, index, sectionIndex) {
    selectedWidgets[index].childComponent[sectionIndex] = data;
    setSelectedWidgets([...selectedWidgets]);
  }
  function pasteFunction(index = undefined) {
    let jsonData = deepClone(props.copyWidgetJson);
    if (jsonData.element == "Copyform") {
      if (index !== undefined) {
      } else {
        selectedWidgets.push(...jsonData.jsonData);
      }
      setSelectedWidgets([...selectedWidgets]);
    } else {
      jsonData["id"] = `${jsonData.element}_${uniqueID(4)}`;
      jsonData.width = jsonData.width ? jsonData.width : 6;
      if (index !== undefined) {
        selectedWidgets[index].childComponent.push(jsonData);
      } else {
        selectedWidgets.push({ childComponent: [jsonData] });
      }
      setSelectedWidgets([...selectedWidgets]);
    }
  }
  function handleMoveWidgetOnDrag(e, json, pathArray) {
    e.dataTransfer.setData(
      "moveWidget",
      JSON.stringify({ json: json, path: pathArray })
    );
  }
  function removeWidgetAfterDrop(pathArray) {
    if (!dragErrorStatus) {
      if (selectedWidgets[pathArray[0]].childComponent.length > 1) {
        selectedWidgets[pathArray[0]].childComponent.splice(pathArray[1], 1);
      } else {
        selectedWidgets.splice(pathArray[0], 1);
      }
      setSelectedWidgets([...selectedWidgets]);
    } else {
      dragErrorStatus = false;
    }
  }

  const getMapItems = (item, index) => {
    let usedWidth = 0;
    let totalWidth = 12;
    selectedWidgets[index].childComponent.map((row) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    return (
      <div className="row">
        {item.childComponent.map((row, refVal) => {
          let Tag = widgetsHtml[row.element];
          return (
            <div
              className={`col-lg-${
                row.width ? row.width : 6
              } position-relative`}
              key={`wid_${row.id}`}
              onMouseLeave={() => {
                props.setActiveWidgetId((prevData) => {
                  let pop = prevData.previousActiveId.pop();
                  return {
                    activeId: pop,
                    previousActiveId: prevData.previousActiveId,
                  };
                });
              }}
              onMouseEnter={(e) => {
                props.setActiveWidgetId((prevData) => {
                  prevData.previousActiveId.push(prevData.activeId);
                  return {
                    activeId: row.id,
                    previousActiveId: prevData.previousActiveId,
                  };
                });
              }}
              draggable
              onDragStart={(e) => {
                if (!["Section", "RepeatingSection"].includes(row.element))
                  handleMoveWidgetOnDrag(e, row, [index, refVal]);
              }}
              onDragEnd={(e) => {
                if (!["Section", "RepeatingSection"].includes(row.element))
                  setTimeout(() => {
                    removeWidgetAfterDrop([index, refVal]);
                  }, 300);
              }}
            >
              <button
                className="addColumnButton"
                onClick={() => {
                  setActiveDropBox([index, refVal]);
                }}
              >
                <i className="fas fa-plus-circle"></i>
              </button>
              <div className="row">
                {activeDropBox.length > 0 &&
                  activeDropBox[0] == index &&
                  activeDropBox[1] == refVal && (
                    <div
                      className="col-lg-2"
                      onDrop={(e) => {
                        setActiveDropBox([]);
                        props.activeDropBoxHandleOnDrop(
                          e,
                          [index],
                          refVal,
                          widthRemaining
                        );
                      }}
                      onDragOver={handleDragOver}
                    >
                      <div
                        className="widgedropBox"
                        onMouseEnter={(e) => {
                          if (e.target.childNodes[0].className == "icons")
                            e.target.childNodes[0].style.display =
                              "inline-block";
                        }}
                        onMouseLeave={(e) => {
                          if (
                            e.target.childNodes.length == 2 &&
                            e.target.childNodes[0].className &&
                            e.target.childNodes[0].className == "icons"
                          )
                            e.target.childNodes[0].style.display = "none";
                        }}
                      >
                        {props.copyWidgetJson &&
                          props.copyWidgetJson.hasOwnProperty("element") && (
                            <span className="icons">
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  pasteFunction(index);
                                }}
                              >
                                <i className="fas fa-paste"></i> <br />
                                Paste
                              </a>
                            </span>
                          )}
                        <span>Drop Fields Here</span>
                      </div>
                    </div>
                  )}
                <div
                  className={
                    activeDropBox[0] == index && activeDropBox[1] == refVal
                      ? "col-lg-10"
                      : "col"
                  }
                >
                  <div className="form-group">
                    <span
                      className="icons"
                      style={
                        props.activeWidgetId.activeId == row.id
                          ? {
                              display: "inline-flex",
                              zIndex: 1,
                            }
                          : {}
                      }
                    >
                      <a
                        role="button"
                        type="button"
                        onClick={() => {
                          setSelectedIndex([index, refVal]);
                          setSelectedWidget(row);
                          props.setIsOpen(true);
                        }}
                      >
                        <i className="fa-regular fa-pen-to-square"></i> <br />{" "}
                        Edit
                      </a>
                      {!row.fixed ? (
                        <>
                          {!row.hasOwnProperty("widthEditable") && (
                            <>
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  row.width > 2
                                    ? (row.width -= 1)
                                    : (row.width = row.width ? row.width : 4);
                                  selectedWidgets[index].childComponent[
                                    refVal
                                  ] = row;
                                  setSelectedWidgets([...selectedWidgets]);
                                }}
                              >
                                <i className="fa-solid fa-arrow-left"></i>{" "}
                                <br /> Shrink
                              </a>
                              <a
                                role="button"
                                disabled={row.width == 12}
                                type="button"
                                onClick={() => {
                                  if (widthRemaining > 0) {
                                    row.width < 12
                                      ? (row.width += 1)
                                      : (row.width = row.width ? row.width : 8);
                                    selectedWidgets[index].childComponent[
                                      refVal
                                    ] = row;
                                    setSelectedWidgets([...selectedWidgets]);
                                  }
                                }}
                              >
                                <i className="fa-solid fa-arrow-right"></i>{" "}
                                <br /> Grow
                              </a>
                              <a
                                role="button"
                                type="button"
                                onClick={() => [
                                  props.setCopyWidgetJson({ ...row }),
                                ]}
                              >
                                <i className="fa-solid fa-copy"></i>
                                <br /> Copy
                              </a>
                            </>
                          )}
                          <a
                            role="button"
                            type="button"
                            onClick={() => {
                              removeWidget(index, refVal);
                            }}
                          >
                            <i className="fa-solid fa-trash"></i> <br /> Delete
                          </a>
                          {/* <a role="button" type="button"><i className="fa-solid fa-ellipsis-vertical"></i> <br /> Move</a> */}
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                    {
                      <span className={`rowId ${props.showIds ? "show" : ""}`}>
                        {row.id}
                      </span>
                    }

                    <Tag
                      index={index}
                      data={row}
                      onChangeFields={onChangeFields}
                      handleOnDrop={handleOnDrop}
                      UpdateSectionUpdate={UpdateSectionUpdate}
                      sectionIndex={refVal}
                      setSelectedWidget={setSelectedWidget}
                      setIsOpen={props.setIsOpen}
                      setSelectedIndex={(arrayPath) => {
                        arrayPath.unshift(refVal);
                        arrayPath.unshift(index);
                        setSelectedIndex(arrayPath);
                      }}
                      setActiveWidgetId={props.setActiveWidgetId}
                      activeWidgetId={props.activeWidgetId}
                      activeDropBoxHandleOnDrop={(
                        e,
                        arrayPath,
                        updateIndex,
                        widthRemaining
                      ) => {
                        arrayPath.unshift(refVal);
                        arrayPath.unshift(index);
                        props.activeDropBoxHandleOnDrop(
                          e,
                          arrayPath,
                          updateIndex,
                          widthRemaining
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {widthRemaining > 0 && (
          <div
            className="col"
            onDrop={(e) => sectionHandleOnDrop(e, index)}
            onDragOver={handleDragOver}
          >
            <div
              className="widgedropBox"
              onMouseEnter={(e) => {
                if (e.target.childNodes[0].className == "icons")
                  e.target.childNodes[0].style.display = "inline-block";
              }}
              onMouseLeave={(e) => {
                if (
                  e.target.childNodes.length == 2 &&
                  e.target.childNodes[0].className &&
                  e.target.childNodes[0].className == "icons"
                )
                  e.target.childNodes[0].style.display = "none";
              }}
            >
              {props.copyWidgetJson &&
                props.copyWidgetJson.hasOwnProperty("element") && (
                  <span className="icons">
                    <a
                      role="button"
                      type="button"
                      onClick={() => {
                        pasteFunction(index);
                      }}
                    >
                      <i className="fas fa-paste"></i> <br />
                      Paste
                    </a>
                  </span>
                )}
              <span>Drop Fields Here</span>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div key={props.data.id} id={props.data.id}>
      <h4 className="sectionTitle">
        {props.data.label ? props.data.label : "Title Name"} :
      </h4>
      <div className="widget-dropsecton">
        {selectedWidgets.map((row, index) => {
          return getMapItems(row, index);
        })}
        <div
          className="row"
          onDrop={handleOnDrop}
          onDragOver={handleDragOver}
          onClick={() => props.setIsOpen(false)}
        >
          <div className="col-lg-6">
            <div
              className="widgedropBox"
              style={{ minHeight: "100px" }}
              onMouseEnter={(e) => {
                if (e.target.childNodes[0].className == "icons")
                  e.target.childNodes[0].style.display = "inline-block";
              }}
              onMouseLeave={(e) => {
                if (
                  e.target.childNodes.length == 2 &&
                  e.target.childNodes[0].className &&
                  e.target.childNodes[0].className == "icons"
                )
                  e.target.childNodes[0].style.display = "none";
              }}
            >
              {props.copyWidgetJson &&
                props.copyWidgetJson.hasOwnProperty("element") && (
                  <span className="icons">
                    <a
                      role="button"
                      type="button"
                      onClick={() => {
                        pasteFunction();
                      }}
                    >
                      <i className="fas fa-paste"></i> <br />
                      Paste
                    </a>
                  </span>
                )}
              <span>Drop Fields Here</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RepeatingSection = (props) => {
  const [activeDropBox, setActiveDropBox] = useState([]);
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  let dragErrorStatus = false;

  useEffect(() => {
    setSelectedWidgets([...props.data.childComponent]);
  }, []);
  const [selectedWidget, setSelectedWidget] = useState({});
  useEffect(() => {
    if (selectedWidget.hasOwnProperty("element"))
      props.setSelectedWidget(selectedWidget);
  }, [selectedWidget]);
  useEffect(() => {
    if (props.data && props.data.childComponent && selectedWidgets.length > 0) {
      props.data.childComponent = [...selectedWidgets];
      props.UpdateSectionUpdate(props.data, props.index, props.sectionIndex);
    }
  }, [selectedWidgets]);

  const [selectedIndex, setSelectedIndex] = useState([0, 0]);
  useEffect(() => {
    props.setSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  const onChangeFields = (item, e, opt = null) => {
    console.log("onChangeFields ===>", e);
  };

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleOnDrop(e, index, subIndex) {
    let widgetType = JSON.parse(
      e.dataTransfer.getData("moveWidget") ||
        e.dataTransfer.getData("widgetType") ||
        null
    );
    if (widgetType !== undefined || widgetType !== null) {
      if (widgetType) {
        let jsonData = {};
        if (typeof widgetType === "string") {
          if (Widgets[widgetType] !== undefined) {
            jsonData.childComponent = [deepClone(Widgets[widgetType])];
          } else if (Layout[widgetType] !== undefined) {
            jsonData.childComponent = [deepClone(Layout[widgetType])];
          } else {
            jsonData.childComponent = [deepClone(Media[widgetType])];
          }
          jsonData.childComponent[0]["id"] = `${
            jsonData.childComponent[0].element
          }_${uniqueID(4)}`;
          jsonData.childComponent[0].width = jsonData.childComponent[0].width
            ? jsonData.childComponent[0].width
            : 6;
          if (
            index !== undefined &&
            subIndex !== undefined &&
            selectedWidgets[index]
          ) {
            selectedWidgets[index].childComponent[subIndex].childComponent.push(
              jsonData
            );
          } else {
            selectedWidgets.push(jsonData);
          }
        } else {
          if (widgetType.hasOwnProperty("json")) {
            selectedWidgets.push({ childComponent: [widgetType.json] });
          } else {
            selectedWidgets.push({ childComponent: [widgetType] });
          }
        }
        setSelectedWidgets([...selectedWidgets]);
      }
    }
    e.dataTransfer.setData("moveWidget", null);
    e.dataTransfer.setData("widgetType", null);
  }

  function sectionHandleOnDrop(e, index) {
    let widgetType = JSON.parse(
      e.dataTransfer.getData("moveWidget") ||
        e.dataTransfer.getData("widgetType") ||
        null
    );
    let width = 0;
    let usedWidth = 0;
    let totalWidth = 12;
    if (selectedWidgets[index] == undefined) {
      if (widgetType.path[0] < index) {
        index -= 1;
      } else {
        index += 1;
      }
    }
    selectedWidgets[index].childComponent.map((row, refVal) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    if (widthRemaining > 6) {
      width = 6;
    } else {
      width = widthRemaining;
    }
    let widgetJson = {};
    if (typeof widgetType == "string") {
      if (Widgets[widgetType] !== undefined) {
        widgetJson = deepClone(Widgets[widgetType]);
      } else if (Layout[widgetType] !== undefined) {
        widgetJson = deepClone(Layout[widgetType]);
      } else {
        widgetJson = deepClone(Media[widgetType]);
      }

      widgetJson["id"] = `${widgetJson.element}_${uniqueID(4)}`;
    } else {
      widgetJson = widgetType.json;
    }

    if (
      widgetJson.hasOwnProperty("minRequiredWidth") &&
      widgetJson.minRequiredWidth > width
    ) {
      dragErrorStatus = false;
      alert(
        `Not allowed, minimum width reduired is ${widgetJson.minRequiredWidth}`
      );
    } else {
      selectedWidgets[index].childComponent.push(
        deepClone({ ...widgetJson, ...{ width: width } })
      );
      setSelectedWidgets([...selectedWidgets]);
    }
    e.dataTransfer.setData("moveWidget", null);
    e.dataTransfer.setData("widgetType", null);
  }

  function removeWidget(index, refVal) {
    if (selectedWidgets[index].childComponent.length > 1) {
      selectedWidgets[index].childComponent.splice(refVal, 1);
    } else {
      selectedWidgets.splice(index, 1);
    }
    setSelectedWidgets([...selectedWidgets]);
  }

  function UpdateSectionUpdate(data, index, sectionIndex) {
    selectedWidgets[index].childComponent[sectionIndex] = data;
    setSelectedWidgets([...selectedWidgets]);
  }

  function pasteFunction(index = undefined) {
    let jsonData = deepClone(props.copyWidgetJson);
    if (jsonData.element == "Copyform") {
      if (index == undefined) {
        selectedWidgets.push(...jsonData.jsonData);
      }
      setSelectedWidgets([...selectedWidgets]);
    } else {
      jsonData["id"] = `${jsonData.element}_${uniqueID(4)}`;
      jsonData.width = jsonData.width ? jsonData.width : 6;
      if (index !== undefined) {
        selectedWidgets[index].childComponent.push(jsonData);
      } else {
        selectedWidgets.push({ childComponent: [jsonData] });
      }
      setSelectedWidgets([...selectedWidgets]);
    }
  }
  function handleMoveWidgetOnDrag(e, json, pathArray) {
    e.dataTransfer.setData(
      "moveWidget",
      JSON.stringify({ json: json, path: pathArray })
    );
  }
  function removeWidgetAfterDrop(pathArray) {
    if (!dragErrorStatus) {
      if (selectedWidgets[pathArray[0]].childComponent.length > 1) {
        selectedWidgets[pathArray[0]].childComponent.splice(pathArray[1], 1);
      } else {
        selectedWidgets.splice(pathArray[0], 1);
      }
      setSelectedWidgets([...selectedWidgets]);
    } else {
      dragErrorStatus = false;
    }
  }

  const getMapItems = (item, index) => {
    let usedWidth = 0;
    let totalWidth = 12;
    selectedWidgets[index].childComponent.map((row) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    return (
      <div className="row">
        {item.childComponent.map((row, refVal) => {
          let Tag = widgetsHtml[row.element];
          return (
            <div
              className={`col-lg-${
                row.width ? row.width : 6
              } position-relative`}
              key={row.id}
              onMouseLeave={() => {
                props.setActiveWidgetId((prevData) => {
                  let pop = prevData.previousActiveId.pop();
                  return {
                    activeId: pop,
                    previousActiveId: prevData.previousActiveId,
                  };
                });
              }}
              onMouseEnter={(e) => {
                props.setActiveWidgetId((prevData) => {
                  prevData.previousActiveId.push(prevData.activeId);
                  return {
                    activeId: row.id,
                    previousActiveId: prevData.previousActiveId,
                  };
                });
              }}
              draggable
              onDragStart={(e) => {
                if (!["Section", "RepeatingSection"].includes(row.element))
                  handleMoveWidgetOnDrag(e, row, [index, refVal]);
              }}
              onDragEnd={(e) => {
                if (!["Section", "RepeatingSection"].includes(row.element))
                  setTimeout(() => {
                    removeWidgetAfterDrop([index, refVal]);
                  }, 300);
              }}
            >
              <button
                className="addColumnButton"
                onClick={() => {
                  setActiveDropBox([index, refVal]);
                }}
              >
                <i className="fas fa-plus-circle"></i>
              </button>
              <div className="row">
                {activeDropBox.length > 0 &&
                  activeDropBox[0] == index &&
                  activeDropBox[1] == refVal && (
                    <div
                      className="col-lg-2"
                      onDrop={(e) => {
                        setActiveDropBox([]);
                        props.activeDropBoxHandleOnDrop(
                          e,
                          [index],
                          refVal,
                          widthRemaining
                        );
                      }}
                      onDragOver={handleDragOver}
                    >
                      <div
                        className="widgedropBox"
                        onMouseEnter={(e) => {
                          if (e.target.childNodes[0].className == "icons")
                            e.target.childNodes[0].style.display =
                              "inline-block";
                        }}
                        onMouseLeave={(e) => {
                          if (
                            e.target.childNodes.length == 2 &&
                            e.target.childNodes[0].className &&
                            e.target.childNodes[0].className == "icons"
                          )
                            e.target.childNodes[0].style.display = "none";
                        }}
                      >
                        {props.copyWidgetJson &&
                          props.copyWidgetJson.hasOwnProperty("element") && (
                            <span className="icons">
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  pasteFunction(index);
                                }}
                              >
                                <i className="fas fa-paste"></i> <br />
                                Paste
                              </a>
                            </span>
                          )}
                        <span>Drop Fields Here</span>
                      </div>
                    </div>
                  )}
                <div
                  className={
                    activeDropBox[0] == index && activeDropBox[1] == refVal
                      ? "col-lg-10"
                      : "col"
                  }
                >
                  <div className="form-group">
                    <span
                      className="icons"
                      style={
                        props.activeWidgetId.activeId == row.id
                          ? {
                              display: "inline-flex",
                              zIndex: 1,
                            }
                          : {}
                      }
                    >
                      <a
                        role="button"
                        type="button"
                        onClick={() => {
                          setSelectedIndex([index, refVal]);
                          setSelectedWidget(row);
                          props.setIsOpen(true);
                        }}
                      >
                        <i className="fa-regular fa-pen-to-square"></i> <br />{" "}
                        Edit
                      </a>
                      {!row.fixed ? (
                        <>
                          {!row.hasOwnProperty("widthEditable") && (
                            <>
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  row.width > 2
                                    ? (row.width -= 1)
                                    : (row.width = row.width ? row.width : 4);
                                  selectedWidgets[index].childComponent[
                                    refVal
                                  ] = row;
                                  setSelectedWidgets([...selectedWidgets]);
                                }}
                              >
                                <i className="fa-solid fa-arrow-left"></i>{" "}
                                <br /> Shrink
                              </a>
                              <a
                                role="button"
                                disabled={row.width == 12}
                                type="button"
                                onClick={() => {
                                  if (widthRemaining > 0) {
                                    row.width < 12
                                      ? (row.width += 1)
                                      : (row.width = row.width ? row.width : 8);
                                    selectedWidgets[index].childComponent[
                                      refVal
                                    ] = row;
                                    setSelectedWidgets([...selectedWidgets]);
                                  }
                                }}
                              >
                                <i className="fa-solid fa-arrow-right"></i>{" "}
                                <br /> Grow
                              </a>
                              <a
                                role="button"
                                type="button"
                                onClick={() => [
                                  props.setCopyWidgetJson({ ...row }),
                                ]}
                              >
                                <i className="fa-solid fa-copy"></i>
                                <br /> Copy
                              </a>
                            </>
                          )}
                          <a
                            role="button"
                            type="button"
                            onClick={() => {
                              removeWidget(index, refVal);
                            }}
                          >
                            <i className="fa-solid fa-trash"></i> <br /> Delete
                          </a>
                          {/* <a role="button" type="button"><i className="fa-solid fa-ellipsis-vertical"></i> <br /> Move</a> */}
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                    {
                      <span className={`rowId ${props.showIds ? "show" : ""}`}>
                        {row.id}
                      </span>
                    }

                    <Tag
                      index={index}
                      data={row}
                      onChangeFields={onChangeFields}
                      handleOnDrop={handleOnDrop}
                      UpdateSectionUpdate={UpdateSectionUpdate}
                      sectionIndex={refVal}
                      setSelectedWidget={setSelectedWidget}
                      setIsOpen={props.setIsOpen}
                      setSelectedIndex={(arrayPath) => {
                        arrayPath.unshift(refVal);
                        arrayPath.unshift(index);
                        setSelectedIndex(arrayPath);
                      }}
                      setActiveWidgetId={props.setActiveWidgetId}
                      activeWidgetId={props.activeWidgetId}
                      activeDropBoxHandleOnDrop={(
                        e,
                        arrayPath,
                        updateIndex,
                        widthRemaining
                      ) => {
                        arrayPath.unshift(refVal);
                        arrayPath.unshift(index);
                        props.activeDropBoxHandleOnDrop(
                          e,
                          arrayPath,
                          updateIndex,
                          widthRemaining
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {widthRemaining > 0 && (
          <div
            className="col"
            onDrop={(e) => sectionHandleOnDrop(e, index)}
            onDragOver={handleDragOver}
          >
            <div
              className="widgedropBox"
              onMouseEnter={(e) => {
                if (e.target.childNodes[0].className == "icons")
                  e.target.childNodes[0].style.display = "inline-block";
              }}
              onMouseLeave={(e) => {
                if (
                  e.target.childNodes.length == 2 &&
                  e.target.childNodes[0].className &&
                  e.target.childNodes[0].className == "icons"
                )
                  e.target.childNodes[0].style.display = "none";
              }}
            >
              {props.copyWidgetJson &&
                props.copyWidgetJson.hasOwnProperty("element") && (
                  <span className="icons">
                    <a
                      role="button"
                      type="button"
                      onClick={() => {
                        pasteFunction(index);
                      }}
                    >
                      <i className="fas fa-paste"></i> <br />
                      Paste
                    </a>
                  </span>
                )}
              <span>Drop Fields Here</span>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div key={props.data.id} id={props.data.id}>
      <h4 className="sectionTitle">
        {props.data.label ? props.data.label : "Title Name"} :
      </h4>
      <div className="widget-dropsecton">
        <h5 className="subtitle">
          <img src={crossIcon} />
          {props.data.itemLabel ? props.data.itemLabel : "Item"} 1
        </h5>

        {selectedWidgets.map((row, index) => {
          return getMapItems(row, index);
        })}
        <div
          className="row"
          onDrop={handleOnDrop}
          onDragOver={handleDragOver}
          onClick={() => props.setIsOpen(false)}
        >
          <div className="col-lg-6">
            <div
              className="widgedropBox"
              style={{ minHeight: "100px" }}
              onMouseEnter={(e) => {
                if (e.target.childNodes[0].className == "icons")
                  e.target.childNodes[0].style.display = "inline-block";
              }}
              onMouseLeave={(e) => {
                if (
                  e.target.childNodes.length == 2 &&
                  e.target.childNodes[0].className &&
                  e.target.childNodes[0].className == "icons"
                )
                  e.target.childNodes[0].style.display = "none";
              }}
            >
              {props.copyWidgetJson &&
                props.copyWidgetJson.hasOwnProperty("element") && (
                  <span className="icons">
                    <a
                      role="button"
                      type="button"
                      onClick={() => {
                        pasteFunction();
                      }}
                    >
                      <i className="fas fa-paste"></i> <br />
                      Paste
                    </a>
                  </span>
                )}
              <span>Drop Fields Here</span>
            </div>
          </div>
        </div>
        <button className="btn-AddItem">
          <img src={plusIcon} />{" "}
          {props.data.addButtonText ? props.data.addButtonText : "Add Item"}
        </button>
      </div>
    </div>
  );
};

export const Image = ({ data }) => {
  let [imagSrc, setImageSrc] = useState("");
  useEffect(() => {
    fetchS3Link(data.src).then((res) => {
      setImageSrc(res);
    });
  }, [data.src]);
  return (
    <>
      {data.src ? (
        <img src={imagSrc} alt="Image" className="DynamicImg"></img>
      ) : (
        <span className="defaultIcon">
          <img src={imageIcon} />
        </span>
      )}
    </>
  );
};
export const ImageAvatar = ({ data }) => {
  let [imagSrc, setImageSrc] = useState("");
  useEffect(() => {
    fetchS3Link(data.src).then((res) => {
      setImageSrc(res);
    });
  }, [data.src]);
  return (
    <Avatar
      // alt={imageIcon}
      src={imagSrc}
      sx={{ width: 56, height: 56 }}
    />
  );
};
export const Video = ({ data }) => {
  let [Src, setSrc] = useState("");
  useEffect(() => {
    fetchS3Link(data.src).then((res) => {
      setSrc(res);
    });
  }, [data.src]);
  return (
    <>
      {data.src && Src !== "" ? (
        <>
          <video width="100%" height={(data.width * 315) / 6} controls autoPlay>
            <source src={Src} type="video/mp4" />
            <source src={Src} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        <span className="defaultIcon">
          <img src={videoIcon} />
        </span>
      )}
    </>
  );
};
export const SelectTimePicker = ({ data, onChangeFields }) => {
  return (
    <form key={data.id} id={data.id}>
      <label>
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmpty(data.label)
              ? highlightTextWithBackground(data.label)
              : "Untitled",
          }}
        />
        <small>{data.required ? "" : " Optional"}</small>
      </label>
      <br />
      <TimePicker
        onChange={(e) => onChangeFields(data, e._i)}
        defaultValue={new Date()}
        showSecond={false}
        use12Hours
        value={new moment()}
        name={data.label ? data.label : "Untitled"}
      />
    </form>
  );
};
export const RatingScale = ({ data, onChangeFields }) => {
  let ratingType = data.typesOfRating.find(
    (type) => type.type == data.selectedRatingType
  );
  return (
    <form key={data.id} id={data.id}>
      <label>
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmpty(data.label)
              ? highlightTextWithBackground(data.label)
              : "Untitled",
          }}
        />
        <small>{data.required ? "" : " Optional"}</small>
      </label>
      <br />
      <div className="questionSection">
        <table className="table">
          <thead>
            <tr>
              <td></td>
              {ratingType.structure.map((row, index) => {
                return (
                  <td key={`choice__${index}`} className="text-center">
                    {row}
                  </td>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {data.fields.map((row, index) => {
              return (
                <tr key={`choice__${index}`}>
                  <td>{row.label}</td>
                  {ratingType.structure.map((radio, radioIndex) => {
                    return (
                      <td className="text-center">
                        <input
                          type="radio"
                          name={index}
                          defaultChecked={radioIndex == data.defaultRadioValue}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </form>
  );
};
export const Signature = ({ data, onChangeFields }) => {
  const [signatureType, setSignatureType] = useState("Draw");
  const [imageURL, setImageURL] = useState(null);
  const sigCanvas = useRef();
  function create() {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setImageURL(URL);
  }

  return (
    <form id={data.id}>
      <label className="signatureHeading">
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmpty(data.label)
              ? highlightTextWithBackground(data.label)
              : "Untitled",
          }}
        />
        <small>{data.required ? "" : " Optional"}</small>
      </label>
      <div className="signatureSection" key={data.id} id={data.id}>
        {signatureType === "Draw" ? (
          <>
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 117 * data.width,
                height: "auto",
                className: "sigCanvas",
                border: "1px solid black",
              }}
              ref={sigCanvas}
            />
          </>
        ) : (
          <input className="signatureText" type="text" />
        )}
      </div>

      <div className="col-12 mt-3">
        <label>Name</label>
        <input
          name="Name"
          type="text"
          className="form-control"
          id={data.fields?.["Name"].id}
          defaultValue={data.fields?.["Name"].value}
        />
      </div>

      <div className="signatureBtns">
        <button type="button" onClick={() => sigCanvas.current.clear()}>
          Clear
        </button>
        <button type="button" onClick={() => setSignatureType("Draw")}>Draw</button>
        <button type="button" onClick={() => setSignatureType("Text")}>Text</button>

        <button
          className="create"
          style={{ display: "none" }}
          onClick={() => create()}
        >
          Create
        </button>
      </div>
    </form>
  );
};

function CrouselImage({ row }) {
  let [imagSrc, setImageSrc] = useState("");
  useEffect(() => {
    fetchS3Link(row.src).then((res) => {
      setImageSrc(res);
    });
  }, []);
  return <img key={row.id} src={imagSrc} />;
}

export const ImageSlider = ({ data }) => {
  data.images.map((row) => {});

  return data.images[0].src ? (
    <Carousel interval="5" verticalSwipe="standard">
      {data.images &&
        data.images.map((row, index) => {
          return (
            <div key={`choice__${index}`}>
              <CrouselImage row={deepClone(row)} />
              <p className="legend">{row.src}</p>
            </div>
          );
        })}
    </Carousel>
  ) : (
    <span className="defaultIcon carouselIcon">
      <img src={carouselIcon} />
    </span>
  );
};

export const ContactInformation = ({ data, onChangeFields }) => {
  return (
    <form id={data.id}>
      <label className="contactInfoHeading">
        Contact Information:<small>{data.required ? "" : " Optional"}</small>
      </label>
      <div className="contactInfo">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>MI./ Name</label>
              <input
                type="text"
                className="form-control"
                id={data.fields["Name"].id}
                name="Name"
                defaultValue={data.fields["Name"].value}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Contact Ph:</label>
              <input
                type="number"
                className="form-control"
                id={data.fields["ContactPh"].id}
                name="ContactPh"
                defaultValue={data.fields["ContactPh"].value}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                id={data.fields["Address"].id}
                name="Address"
                defaultValue={data.fields["Address"].value}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                id={data.fields["City"].id}
                name="City"
                defaultValue={data.fields["City"].value}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <div className="row">
                <div className="col-lg-6">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    id={data.fields["State"].id}
                    name="State"
                    defaultValue={data.fields["State"].value}
                  />
                </div>

                <div className="col-lg-6">
                  <label>Zip</label>
                  <input
                    type="Number"
                    className="form-control"
                    id={data.fields["Zip"].id}
                    name="Zip"
                    defaultValue={data.fields["Zip"].value}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Contact Email</label>
              <input
                type="text"
                className="form-control"
                id={data.fields["ContactEmail"].id}
                name="ContactEmail"
                defaultValue={data.fields["ContactEmail"].value}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export const Content = ({ data }) => {
  return (
    <div key={data.id} id={data.id}>
      <p
        style={{
          fontWeight: data.bold ? "bold" : "",
          fontStyle: data.italic ? "italic" : "",
        }}
        dangerouslySetInnerHTML={{
          __html: data.DynamicContent
            ? JSON.parse(data.DynamicContent)
            : "Dynamic Content Here",
        }}
      ></p>
    </div>
  );
};
