import {isEmpty} from "./isEmpty";
import * as _ from "lodash";
import * as moment from "moment";

export const convertToUrl = (inputString) => {
  // Check if the inputString already starts with a protocol
  if (/^(https?|ftp):\/\//i.test(inputString)) {
    return inputString; // Already a valid URL
  } else {
    // If not, prepend "http://" and return the modified string
    return "http://" + inputString;
  }
}

export const removeSpace = (inputString) => !isEmpty(inputString) ? inputString.replace(/ /g, '_').toLowerCase() : inputString;
export const addSpace = (inputString) => !isEmpty(inputString) ? inputString.replace(/_/g, ' ').toLowerCase() : inputString;
export const removeAfter = (inputString, separator) => !isEmpty(inputString) ? inputString.split(separator)[0] : inputString
export const removeHttp = (inputString) => !isEmpty(inputString) ? inputString.replace(/^https?:\/\//, "") : inputString;
export const evaluateExpression = (data, stringValues) => {
  try {
    let expression = convertStringToExpression(data?.calculation)
    const returnType = data?.type || "number"
    const decimalPlace = data?.decimal || 0
    if (expression.startsWith("=")) {
      expression = expression.slice(1)
    }

    const replacedExpression = expression.replace(/(\$\w+)/g, (match, stringId) => {
      const id = stringId.slice(1);
      return stringValues[id] !== undefined ? typeof stringValues[id] === "object" ? _.reduce(stringValues[id], (sum, n) => {
        return sum + _.toNumber(n);
      }, 0) : _.toNumber(stringValues[id]) : match;
    });

    try {
      const result = eval(replacedExpression);
      return returnType === "percent" ? `${result*100}%` : returnType === "currency" ? `$${result.toFixed(2)}` : result.toFixed(decimalPlace);
    } catch (error) {
      //console.error('Error evaluating expression:', error);
      return null;
    }
  } catch (e) {
  }
};

export const convertStringToExpression = (inputString) => {
  return !isEmpty(inputString) ? inputString.replace(/\$[^_]*__/g, '$') : "";
}
export const fetchEncodedString = (inputString) => {
  return !isEmpty(inputString) ? inputString.replace(/\$[^_]*__/g, '$') : "";
}
export const replaceLabelConstToValues = (text, values) => {
  try {
    const finalValues = {};
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value === "object") {
        Object.entries(value).forEach(([key2, value2]) => {
          finalValues[`${key}.${key2}`] = value2
        })
      } else {
        finalValues[key] = value;
      }
    })

    Object.entries(finalValues).forEach(([key, value]) => {
      const placeholder = `{{${key}}}`;
      const convertedVal = !isEmpty(value) ?
        (isTimeOrDatePicker(key) === "timePicker" && isDateOrTime(value)) ? moment(value).format('hh:mm A')
          : (isTimeOrDatePicker(key) === "datePicker" && isDateOrTime(value)) ? formatDate(value, 'DD/MM/YYYY')
            : typeof value === "object" ? _.reduce(value, (sum, n) => { return typeof sum === "string" ? (sum + ' ' + n) : sum + _.toNumber(n); }, "")
              : value
        : "";

      text = text.replace(new RegExp(placeholder, 'g'), convertedVal);
    });
    return text.replace(/{{|}}/g, '');
  } catch (e) {
    return text;
  }
}
export const replaceLabelStrings = (data, stringValues) => {
  if (!isEmpty(data)) {
    try {
      return replaceLabelConstToValues(data, stringValues);
    } catch (e) {
      return data;
    }
  } else {
    return "Untitled"
  }
}
const formatDate = (dateString, format) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  const formattedDate = date.toLocaleString('en-US', options);

  if (format) {
    // Additional formatting if specified
    // Example: "YYYY-MM-DD HH:mm:ss"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return format
      .replace('YYYY', year)
      .replace('MM', month)
      .replace('DD', day)
      .replace('HH', hours)
      .replace('mm', minutes)
      .replace('ss', seconds);
  }

  return formattedDate;
}
const isDateOrTime = (input) => {
  const parsedDate = moment(input);
  return parsedDate.isValid();
}
const isTimeOrDatePicker = (str) => {
  const timePickerRegex = /^SelectTimePicker_([a-zA-Z0-9]+)$/;
  const datePickerRegex = /^SelectDatePicker_([a-zA-Z0-9]+)$/;

  if (timePickerRegex.test(str)) {
    return "timePicker";
  } else if (datePickerRegex.test(str)) {
    return "datePicker";
  } else {
    return typeof str;
  }
}
export const highlightTextWithBackground = (inputText) => {
  const regex = /{{(.*?)}}/g; // Regular expression to match text within {{ }}
  return inputText.replace(regex, `<span style="background-color: #dfeaea; color: #55727b; border-color: rgba(0,0,0,0)">$1</span>`);
}