import { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  deepClone,
  getConditionalStringResponse,
} from "../../shared/functions";
import crossIcon from "../../assets/img/iconCross.svg";
import * as formHtml from "../../shared/constants/formHtml";
import { formatQuery } from "react-querybuilder";
import { Image } from "../../shared/constants/widgetsHtml";

function PreviewModal({
  openPreviewModal,
  setOpenPreviewModal,
  FormHeader,
  SubmitForm,
  selectedWidgets,
  setSelectedWidget,
  setFormSettings,
  formSettings,
  setIsOpen,
}) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "100%",
      bottom: "auto",
      marginRight: "-30%",
      transform: "translate(-50%, -50%)",
      width: "70%",
    },
  };
  const [form, setForm] = useState({});
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [splitForm, setSplitForm] = useState([]);
  const [previewWidgetsData, setPreviewWidgetsData] = useState([]);

  const [WidgetsData] = useState(
    JSON.parse(localStorage.getItem("previewWidgetsData"))
  );
  const [settings] = useState(JSON.parse(localStorage.getItem("formSettings")));

  useEffect(() => {
    if (WidgetsData && WidgetsData.length > 0) {
      if (settings.title.pages.length > 0) {
        let Obj = [];
        let paginateForm = [];
        WidgetsData.map((json, index) => {
          json.childComponent.map((data, refVal) => {
            if (data.element == "PageBreak") {
              data.structure = deepClone(Obj);
              Obj = [];
              paginateForm.push(data);
            } else {
              if (refVal == 0) {
                Obj.push(json);
              }
              if (index == WidgetsData.length - 1) {
                let submitData = { structure: deepClone(Obj) };
                paginateForm.push(submitData);
              }
            }
          });
        });
        setSplitForm(paginateForm);
      } else {
        setPreviewWidgetsData(WidgetsData);
      }
    } 
  }, [WidgetsData, settings]);

  useEffect(() => {
    if (previewWidgetsData.length > 0) {
      let FormData = {};
      previewWidgetsData.map((json) => {
        json.childComponent.map((data) => {
          if (data.hasOwnProperty("fields")) {
            let structure = {};
            Object.keys(data.fields).map((field) => {
              structure[field] = data.fields[field].value;
            });
            FormData[data.id] = { ...structure };
          } else {
            FormData[data.id] = "";
          }
        });
      });
      setForm({ ...FormData });
    }
  }, [previewWidgetsData]);

  function closeModal() {
    setOpenPreviewModal(false);
    localStorage.removeItem("previewWidgetsData");
    localStorage.removeItem("formSettings");
    localStorage.removeItem("formSubmitSettings");
  }
  return (
    <div>
      <Modal
        isOpen={openPreviewModal}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Save As Modal"
        appElement={document.getElementById("previewModal")}
      >
        <div className="modalHeader">
          <h4>Preview</h4>
          <button
            className="btn btn-closeModal"
            onClick={() => {
              setOpenPreviewModal(false);
            }}
          >
            <img src={crossIcon} />
          </button>
        </div>
        <div className="saveModalBody previewModalBody">
          <div className="previewInnerBody">
            <div className="container" id="previewModal">
              <FormHeader
                hideIcons={true}
                activePage={activePageIndex}
                setSelectedWidget={setSelectedWidget}
                selectedWidgets={selectedWidgets}
                setFormSettings={setFormSettings}
                formSettings={formSettings}
                setIsOpen={setIsOpen}
              />

              {previewWidgetsData.length > 0 && (
                <>
                  {previewWidgetsData.map((data, index) => {
                    return (
                      <div className="row" key={`choice__${index}`}>
                        {data.childComponent.map((row, refVal) => {
                          let Tag = formHtml[row.element];
                          let props = {};
                          if (
                            row.conditionalOperation?.rules.rules.length > 0
                          ) {
                            if (
                              !getConditionalStringResponse(
                                row.conditionalOperation.rules
                              )
                            ) {
                              return;
                            }
                          }

                          return (
                            <div
                              className={`col-lg-${row.width ? row.width : 6}`}
                              key={row.id}
                            >
                              <div className="form-group">
                                {form[row.id] !== undefined && (
                                  <Tag
                                    {...props}
                                    data={row}
                                    onChangeFields={(value) =>
                                      setForm((prevData) => {
                                        prevData[row.id] = value;
                                        return { ...prevData };
                                      })
                                    }
                                    formData={form[row.id]}
                                    form={form}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  <SubmitForm hideIcons={true} />
                </>
              )}
              {splitForm.length > 0 && (
                <>
                  {splitForm[activePageIndex].structure.map((data, index) => {
                    return (
                      <div className="row" key={`choice__${index}`}>
                        {data.childComponent.map((row, refVal) => {
                          let Tag = formHtml[row.element];
                          if (row.conditionalOperation?.rules.rules.length > 0) {
                            if (
                              !getConditionalStringResponse(
                                row.conditionalOperation.rules
                              )
                            ) {
                              return;
                            }
                          }
                          return (
                            <div
                              className={`col-lg-${row.width ? row.width : 6}`}
                              key={row.id}
                            >
                              <div className="form-group">
                                <Tag
                                  data={row}
                                  onChangeFields={(value) =>
                                    setForm((prevData) => {
                                      prevData[row.id] = value;
                                      return { ...prevData };
                                    })
                                  }
                                  formData={form[row.id]}
                                  form={form}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  {activePageIndex == splitForm.length - 1 ? (
                    <SubmitForm hideIcons={true} />
                  ) : (
                    <div>
                      <div className="pagebreak">
                        {splitForm[activePageIndex].buttons.map(
                          (button, index) => {
                            if (button.hidden) {
                              return null;
                            }
                            return (
                              <button
                                key={`choice__${index}`}
                                type="button"
                                className=""
                                disabled={
                                  button.conditionalOperation.rules.rules
                                    .length > 0
                                    ? !getConditionalStringResponse(
                                        button.conditionalOperation.rules
                                      )
                                    : false
                                }
                                onClick={() => {
                                  setActivePageIndex(activePageIndex + 1);
                                }}
                              >
                                {" "}
                                {button.label}
                              </button>
                            );
                          }
                        )}
                        <div className="pagesplit-top"></div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default PreviewModal;
