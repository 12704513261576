import AWS from "aws-sdk";
import { isEmpty } from "./isEmpty";
import * as _ from "lodash";

export function uniqueID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function generatePathString(pathArray, keyName = undefined) {
  let path = "";
  if (pathArray.length > 0) {
    pathArray.map((value, index) => {
      if (index == 0) {
        path += `[${value}]`;
      } else if (index > 0 && index !== pathArray.length - 1) {
        path += `${keyName ? ".childComponent" : ""}[${value}]`;
      }
    });
  }
  return path;
}
export function deepClone(data) {
  return JSON.parse(JSON.stringify(data));
}

export async function fetchImage(Key) {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_S3_BUCKET_SECRET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_S3_BUCKET_ACCESS_KEY_ID,
    region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
    signatureVersion: "v4",
  });
  const s3 = new AWS.S3();
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Key: Key,
    Expires: 3000,
  };
  return await s3.getSignedUrl("getObject", params);
}

export function getConditionalStringResponse(data) {
  let response = data.rules.map((rule) => {
    const { field, operator, value, combinator } = rule;
    if (isEmpty(combinator)) {
      const elField = document
        .getElementById("previewModal")
        .querySelector(`[id='${field}']`);
      if (elField && elField.value.length > 0) {
        let Input = elField.value;
        if (elField && elField.type === "checkbox") {
          let name = elField.name;
          let ele = document
            .getElementById("previewModal")
            .querySelectorAll(`[name='${name}']`);

          const tempVal = [];
          for (let i = 0; i < ele.length; i++) {
            if (ele[i].checked) {
              tempVal.push(ele[i].value);
            }
          }
          Input = _.reduce(
            tempVal,
            (sum, n) => {
              return sum + _.toNumber(n);
            },
            0
          );
        }
        if (elField && elField.type === "radio") {
          let name = elField.name;
          let ele = document
            .getElementById("previewModal")
            .querySelectorAll(`[name='${name}']`);
          for (let i = 0; i < ele.length; i++) {
            if (ele[i].checked) Input = ele[i].value;
          }
        }
        if (operator === "contains") {
          return Input.includes(value);
        } else if (operator === "beginsWith") {
          return Input.startsWith(value);
        } else if (operator === "endsWith") {
          return Input.match(value + "$") == value;
        } else if (operator === "doesNotContain") {
          return Input.indexOf(value) == -1;
        } else if (operator === "doesNotBeginWith") {
          return !Input.startsWith(value);
        } else if (operator === "doesNotEndWith") {
          return !Input.match(value + "$") == value;
        } else if (operator === "null") {
          return isEmpty(Input);
        } else if (operator === "notNull") {
          return !isEmpty(Input);
        } else if (operator === "in") {
          return !Input.includes(value);
        } else if (operator === "notIn") {
          return !Input.includes(value);
        } else if (operator === "between") {
          return Input >= value[0] && Input <= value[1];
        } else if (operator === "notBetween") {
          return Input < value[0] || Input > value[1];
        } else if (operator === "=") {
          return _.isEqual(Input, value); //Input == value;
        } else {
          return eval(`${Input} ${operator} ${JSON.stringify(value)}`);
        }
      } else {
        return false;
      }
    } else {
      return getConditionalStringResponse(rule);
    }
  });
  let operator = `${data.combinator === "and" ? "&& " : "|| "} `;
  let string = "";
  response.map((res, index) => {
    string += `${index !== 0 ? operator : ""}${res} `;
  });
  return eval(string);
}
